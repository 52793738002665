export const environment = {
  production: false,
  panel: "king_",
  domain: "jarvisexch.com",
  baseUrl: "https://jarvisexch.com",
  pollers: {
      oddsData: 600,
      listOddsData: 10000,
      scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  imgPrefix: '/king_client',
  guestEnable: false,
  modalClass: 'bookModal',
  userList: [
      { role_id: 1, id: 'admin', name: 'Admin', cIds: [] },
      { role_id: 2, id: 'super_master', name: 'Super Master', cIds: [1, 2] },
      { role_id: 3, id: 'master', name: 'Master', cIds: [1, 2] },
      { role_id: 4, id: 'client', name: 'Client', cIds: [1, 2, 3] },
  ],
  hidePartenerShips: [9994],
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  host: {
      domain: '',
      name: '',
      dName: '',
      ext: '',
      theme: '',
      email: '',
      themePath: [''],
      pusher: {
          key: '',
          cluster: ''
      },
      guestEnable: false,
      isMarketSetting: false,
      settlementHeading: 'Settlement',
      userListNewDesign: false,
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
  },
  hosts: [
      {
          domain: 'localhost',
          name: 'localhost',
          dName: 'LocalHost',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '40a68fac809117994696',
              cluster: 'eu'
          },
          guestEnable: true,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'sky.com',
          name: 'sky',
          dName: 'admin.sky.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '40a68fac809117994696',
              cluster: 'eu'
          },
          guestEnable: false,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: '2022exch.com',
          name: '2022exch',
          dName: 'admin.2022exch.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '40a68fac809117994696',
              cluster: 'eu'
          },
          guestEnable: false,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: '2023exch.com',
          name: '2023exch',
          dName: 'admin.2023exch.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          themePath: ['assets/css/2023exch.css'],
          pusher: {
              key: '961c82bb314d3e5f22b5',
              cluster: 'eu'
          },
          guestEnable: false,
          isMarketSetting: true,
          settlementHeading: 'Chip Summary',
          userListNewDesign: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'mango777.club',
          name: 'mango777',
          dName: 'admin.mango777.club',
          ext: '.club',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          guestEnable: false,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'angelexch247.com',
          name: 'angelexch247',
          dName: 'admin.angelexch247.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          guestEnable: false,
          isMarketSetting: true,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'shiva247exch.com',
          name: 'shiva247exch',
          dName: 'admin.shiva247exch.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          guestEnable: false,
          isMarketSetting: true,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'play365online.com',
          name: 'play365online',
          dName: 'admin.play365online.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          guestEnable: false,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'play365online.club',
          name: 'play365online',
          dName: 'admin.play365online.club',
          ext: '.club',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          guestEnable: false,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'ashirwad99.com',
          name: 'ashirwad99',
          dName: 'admin.ashirwad99.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          guestEnable: false,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: '911exch.com',
          name: '911exch',
          dName: 'admin.911exch.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          guestEnable: false,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: '777books.com',
          name: '777books',
          dName: 'admin.777books.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          guestEnable: false,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'falconexch.in',
          name: 'falconexch',
          dName: 'admin.falconexch.in',
          ext: '.in',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          themePath: ['assets/css/themes-911exch.css'],
          guestEnable: true,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: false,
      },
      {
          domain: 'falconexch.club',
          name: 'falconexch',
          dName: 'admin.falconexch.club',
          ext: '.club',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-911exch.css'],
          guestEnable: true,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: false,
      },
      {
          domain: 'ak247exch.com',
          name: 'ak247exch',
          dName: 'admin.ak247exch.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-shiva247exch.css'],
          guestEnable: true,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: true
      },
      {
          domain: 'bigbull69.bet',
          name: 'bigbull69',
          dName: 'admin.bigbull69.bet',
          ext: '.bet',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          guestEnable: false,
          isMarketSetting: true,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'freeplay24.com',
          name: 'freeplay24',
          dName: 'admin.freeplay24.com',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: 'fdf9a08dc1f2bfa9cffa',
              cluster: 'eu'
          },
          guestEnable: false,
          isMarketSetting: true,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'gem999.win',
          name: 'gem999',
          dName: 'admin.gem999.win',
          ext: '.win',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          guestEnable: false,
          isMarketSetting: true,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'skybook99.com',
          name: 'skybook99',
          dName: 'admin.skybook99.com',
          ext: '.club',
          theme: 'yellow-body',
          email: '',
          themePath: ['assets/css/theme-skybook99.css'],
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          guestEnable: false,
          isMarketSetting: false,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
          domain: 'crystal247.bet',
          name: 'crystal247',
          dName: 'admin.crystal247.bet',
          ext: '.bet',
          theme: 'yellow-body',
          email: '',
          themePath: [''],
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          guestEnable: false,
          isMarketSetting: true,
          settlementHeading: 'Settlement',
          userListNewDesign: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      },
      {
        domain: 'freedomexch.co',
        name: 'freedomexch',
        dName: 'admin.freedomexch.co',
        ext: '.club',
        theme: 'yellow-body',
        email: '',
        themePath: ['assets/css/theme-skybook99.css'],
        pusher: {
            key: 'c8bbdb36496c73b41567',
            cluster: 'eu'
        },
        guestEnable: false,
        isMarketSetting: false,
        settlementHeading: 'Settlement',
        userListNewDesign: false,
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
    },
  ]
};
